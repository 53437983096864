import { Component, OnInit } from '@angular/core';
import { ProjectList } from './project.config';
import { Project } from './project.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  projectList: Project[] = ProjectList;

  constructor() { }

  ngOnInit(): void {

  }

}
