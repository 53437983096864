import { Project } from "./project.model";

export const ProjectList: Project[] = [
  {
    id: 19,
    name: "Google Wallet",
    img: "../../../assets/images/projects/19.jpg",
    imgHover: "../../../assets/images/projects/19-h.jpg",
    src: "",
    url: "https://wallet.google/",
  },
  {
    id: 18,
    name: "Elephant",
    img: "../../../assets/images/projects/18.jpg",
    imgHover: "../../../assets/images/projects/18-h.jpg",
    src: "",
    url: "https://elephant.is/",
  },
  {
    id: 16,
    name: "Pat-Pat",
    img: "../../../assets/images/projects/16.jpg",
    imgHover: "../../../assets/images/projects/16-h.jpg",
    src: "",
    url: "/project/andy-warhol/pitera-limited-edition/pat-pat/",
  },
  {
    id: 17,
    name: "Recycle Master",
    img: "../../../assets/images/projects/17.jpg",
    imgHover: "../../../assets/images/projects/17-h.jpg",
    src: "",
    url: "/project/andy-warhol/pitera-limited-edition/recycle-master/",
  },
  {
    id: 14,
    name: "Lincoln",
    img: "../../../assets/images/projects/14.jpg",
    imgHover: "../../../assets/images/projects/14-h.jpg",
    src: "",
    url: "https://www.lincoln.com.cn/",
  },
  {
    id: 9,
    name: "The Shallow",
    img: "../../../assets/images/projects/09-h.jpg",
    imgHover: "../../../assets/images/projects/09.jpg",
    src: "",
    url: "/project/the-shallow/",
  },
  {
    id: 11,
    name: "Stay Far East",
    img: "../../../assets/images/projects/11-h.jpg",
    imgHover: "../../../assets/images/projects/11.jpg",
    src: "",
    // url: '/project/stay-far-east',
    url: "http://www.stayfareast.com/en",
  },
  {
    id: 10,
    name: "Unilever LC Mayo",
    img: "../../../assets/images/projects/10-h.jpg",
    imgHover: "../../../assets/images/projects/10.jpg",
    src: "",
    url: "/project/unilever-lcmayo/",
  },
  {
    id: 8,
    name: "Nespresso",
    img: "../../../assets/images/projects/08-h.jpg",
    imgHover: "../../../assets/images/projects/08.jpg",
    src: "",
    url: "/project/nespresso/",
  },
  {
    id: 7,
    name: "Red Cross",
    img: "../../../assets/images/projects/07-h.jpg",
    imgHover: "../../../assets/images/projects/07.jpg",
    src: "",
    url: "/project/redcross/",
  },
  {
    id: 6,
    name: "Atopiclair",
    img: "../../../assets/images/projects/06-h.jpg",
    imgHover: "../../../assets/images/projects/06.jpg",
    src: "",
    url: "/project/atopiclair/",
  },
  {
    id: 12,
    name: "Loop",
    img: "../../../assets/images/projects/12-h.jpg",
    imgHover: "../../../assets/images/projects/12.jpg",
    src: "",
    url: "/project/loop/",
  },
  {
    id: 13,
    name: "Singaporean of the Day",
    img: "../../../assets/images/projects/13.jpg",
    imgHover: "../../../assets/images/projects/13-h.jpg",
    src: "",
    url: "",
  },
  {
    id: 5,
    name: "DBS Multiplier Calculation",
    img: "../../../assets/images/projects/05-h.jpg",
    imgHover: "../../../assets/images/projects/05.jpg",
    src: "",
    url: "/project/dbs-multiplier/",
  },
  {
    id: 4,
    name: "McDonald's Suprise Alarm",
    img: "../../../assets/images/projects/04-h.jpg",
    imgHover: "../../../assets/images/projects/04.jpg",
    src: "",
    url: "/project/mcd-surprise-alarm/",
  },
  {
    id: 3,
    name: "McDelivery",
    img: "../../../assets/images/projects/03-h.jpg",
    imgHover: "../../../assets/images/projects/03.jpg",
    src: "",
    url: "https://www.mcdelivery.com.sg/sg/",
  },
  {
    id: 15,
    name: "Nippon Paint",
    img: "../../../assets/images/projects/15.jpg",
    imgHover: "../../../assets/images/projects/15-h.jpg",
    src: "",
    url: "https://www.nipponpaint.com.my/",
  },
  {
    id: 2,
    name: "CIMB Animation",
    img: "../../../assets/images/projects/02-h.jpg",
    imgHover: "../../../assets/images/projects/02.jpg",
    src: "",
    url: "/project/cimb-animation2/",
  },
  {
    id: 1,
    name: "CIMB Animation",
    img: "../../../assets/images/projects/01-h.jpg",
    imgHover: "../../../assets/images/projects/01.jpg",
    src: "",
    url: "/project/cimb-animation1/",
  },
];
