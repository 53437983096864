<section class="portfolio">
  <ul>
    <li *ngFor="let item of projectList">
      <a href="{{item.url ? item.url : '#'}}" target="_blank">
        <div class="glitch glitch--style-6">

          <div class="glitch__img"><img src="{{item.img}}" alt="{{item.name}}"></div>
          <div class="glitch__img"><img src="{{item.imgHover}}" alt="{{item.name}}"></div>
          <div class="glitch__img"><img src="{{item.imgHover}}" alt="{{item.name}}"></div>
          <div class="glitch__img"><img src="{{item.imgHover}}" alt="{{item.name}}"></div>
          <div class="glitch__img"><img src="{{item.imgHover}}" alt="{{item.name}}"></div>

        </div>
      </a>
    </li>
  </ul>
</section>
